const qrisAcquirerPath = '/saku-bisnis/qris-acquirer';
const qrisAcquirerActivationPath = '/saku-bisnis/qris-acquirer/activation';

export const PathSakuBisnis = {
  // Branda/Menu
  beranda: '/saku-bisnis/beranda',
  akun: '/saku-bisnis/akun',
  editAkun: '/saku-bisnis/akun/edit',
  list: '/saku-bisnis/saku/dashboard',
  addWallet: '/saku-bisnis/saku/tambah-saku',
  detail: '/saku-bisnis/saku/detail/2',
  transactionDetail: '/saku-bisnis/saku/detail/2/[ID]/transaction/[TRREFN]',
  ubahNamaSaku: '/saku-bisnis/saku/settings/ubah-saku',
  pin: '/saku-bisnis/pin',
  tambahSaldoSakuLain: '/saku-bisnis/dari-saku-lain/nominal-tambah-saldo',
  pindahSaldoSakuLain: '/saku-bisnis/dari-saku-lain/nominal-pindah-saldo',
  caraTambahSalodBankLain: '/saku-bisnis/dari-bank-lain/cara-tambah-saldo',
  caraTambahSaldoMobile: '/saku-bisnis/dari-bank-lain/mobile-or-atm/mobile',
  caraTambahSaldoAtm: '/saku-bisnis/dari-bank-lain/mobile-or-atm/atm',
  caraTambahSaldoSms: '/saku-bisnis/dari-bank-lain/sms-banking',
  syaratKetentuan: '/saku-bisnis/registration/terms?isNotOnboarding=true',
  infoSakuBisnis: '/saku-bisnis/saku/info',

  // Registrations
  registrationSyaratKetentuan: '/saku-bisnis/registration/terms',
  onboardingRegistration: '/saku-bisnis/registration/onboarding',
  registrationCreateSaku: '/saku-bisnis/registration/create-saku',
  registrationSuccess: '/saku-bisnis/registration/success-registration',

  // Single Transfer
  singleTransferTransactionStatus: '/saku-bisnis/transfer/single/[ID]/transaction-status',
  singleTransfer: '/saku-bisnis/transfer/single',
  singleTransferInsertAmount: '/saku-bisnis/transfer/single/insert-amount',
  singleTransferNew: '/saku-bisnis/transfer/single/new',
  singleTransferFavorit: '/saku-bisnis/transfer/single/favorite',
  singleTransferConfirmation: '/saku-bisnis/transfer/single/confirmation',
  singleTransferSchedule: '/saku-bisnis/transfer/single/scheduled-transfer',
  singleTransferScheduleSetup: '/saku-bisnis/transfer/single/scheduled-transfer/setup',
  singleTransferScheduleDetail: '/saku-bisnis/transfer/single/scheduled-transfer/%ID%',

  // Transfer
  transferHistory: '/saku-bisnis/transfer/history',

  // Mass Transfer
  massTransfer: '/saku-bisnis/transfer/mass',
  massTransferCart: '/saku-bisnis/transfer/mass/cart',
  massTransferNew: '/saku-bisnis/transfer/mass/new',
  massTransferConfirmation: '/saku-bisnis/transfer/mass/confirmation',
  massTransferTransactionStatus: '/saku-bisnis/transfer/mass/[ID]/transaction-status',
  introMassTransfer: '/saku-bisnis/transfer/mass/intro',

  // Webview Shared Route
  webviewSharedRoute: '/saku-bisnis/webview',

  // QRIS Acquirer
  qrisAcquirer: qrisAcquirerPath,
  qaMerchantInformation: `${qrisAcquirerPath}/merchant/[MERCHANT_ID]`,
  qaStaticQRImage: `${qrisAcquirerPath}/merchant/[MERCHANT_ID]/qr-image`,
  qaInsertAmount: `${qrisAcquirerPath}/merchant/[MERCHANT_ID]/insert-amount`,
  qaDynamicQRImage: `${qrisAcquirerPath}/merchant/[MERCHANT_ID]/qr-image/[TF_ID]`,
  qaDynamicSuccess: `${qrisAcquirerPath}/merchant/[MERCHANT_ID]/qr-image/[TF_ID]/success`,
  qaTfHistory: `${qrisAcquirerPath}/transactions/[TF_ID]`,
  qaTfDetailById: `${qrisAcquirerPath}/transactions/[TF_ID]/detail`,
  onboardingQrisAcquirer: `${qrisAcquirerActivationPath}/onboarding`,
  firstStep: `${qrisAcquirerActivationPath}/first-step`,
  chooseCategory: `${qrisAcquirerActivationPath}/choose-category`,
  inputInformation: `${qrisAcquirerActivationPath}/input-information`,
  inputPhoto: `${qrisAcquirerActivationPath}/input-photo`,
  // QRIS Acquirer Activation
  qrisAcquirerActivationOnboarding: `${qrisAcquirerActivationPath}/onboarding`,
  qrisAcquirerActivationTnc: `${qrisAcquirerActivationPath}/faq`,
  qrisAcquirerActivationFirstStep: `${qrisAcquirerActivationPath}/first-step`,
  qrisAcquirerActivationChooseCategory: `${qrisAcquirerActivationPath}/choose-category`,
  qrisAcquirerActivationChooseSaku: `${qrisAcquirerActivationPath}/choose-saku`,
  qrisAcquirerActivationInputIncome: `${qrisAcquirerActivationPath}/input-income`,
  qrisAcquirerActivationInputAddress: `${qrisAcquirerActivationPath}/input-address`,
  qrisAcquirerActivationChooseProvince: `${qrisAcquirerActivationPath}/choose-province`,
  qrisAcquirerActivationChooseCity: `${qrisAcquirerActivationPath}/choose-city`,
  qrisAcquirerActivationChooseDistrict: `${qrisAcquirerActivationPath}/choose-district`,
  qrisAcquirerActivationChooseUrban: `${qrisAcquirerActivationPath}/choose-urban-village`,
  qrisAcquirerActivationChoosePostalCode: `   ${qrisAcquirerActivationPath}/choose-postal-code`,
  qrisAcquirerActivationInputInformation: `${qrisAcquirerActivationPath}/input-information`,
  qrisAcquirerActivationInputPhoto: `${qrisAcquirerActivationPath}/input-photo`,
  qrisAcquirerActivationConfirmation: `${qrisAcquirerActivationPath}/confirmation`,
};

export const PathPartner = {
  securePartnerData: '/buka-tabungan/partner/password',
  pinPartner: '/buka-tabungan/partner/pin',
};

export const PathSakuAnak = {
  beranda: '/saku-anak/beranda',
  akun: '/saku-anak/akun',
  detail: '/saku-anak/saku/detail',
  caraTambahSaldo: '/saku-anak/cara-tambah-saldo',
  transactionDetail: '/saku-anak/saku/detail/[ID]/transaction/[TRREFN]',
  detailAkun: '/saku-anak/akun/detail',
};

export const PathRayaLottery = {
  home: '/raya-lottery/home',
  history: '/raya-lottery/history',
  historyDetail: '/raya-lottery/history-detail',
  howToGet: '/raya-lottery/how-to-get',
  faq: '/raya-lottery/faq',
};
